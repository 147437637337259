<script>
import moment from "moment-timezone";

export default {
  name: "KpiHealthCoachBanner",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    patients: Array,
  },
  computed: {
    stats() {
      return [
        { name: 'Patients', stat: this.patients.length, target: 150, description: 'Number of Patients in this selection'},
        { name: 'Avg RPM Duration', stat: this.avgRpmDuration, target: this.goal, description: 'Avg. RPM duration in minutes per selected patients for this month' },
        { name: 'Avg CCM Duration', stat: this.avgCcmDuration, target: this.goal, description: 'Avg. CCM duration in minutes per selected patients for this month' },
        { name: 'Goal Duration', stat: this.goal, target: this.authUser.config ? this.authUser.config.task_dur_goal : 60, description: 'The goal in minutes based on time remaining in the month' },
        { name: 'Active Programs', stat: this.activePrograms, target: 0, description: 'Number of active RPM, CCM, and APCM programs (will not count multiple active care plans per category, per patient)' },
      ];
    },
    authUser() {
      return this.$store.state.patients.authUser
    },
    avgRpmDuration() {
      return this.patients.length ? (this.patients.filter(p => p.cache.rpm_date).reduce((sum, p) => sum + (parseInt(p.cache.task_duration_rpm_sum_monthly) || 0), 0) / this.patients.filter(p => p.cache.rpm_date).length).toFixed(0) : 0;
    },
    avgCcmDuration() {
      return this.patients.length ? (this.patients.filter(p => p.cache.ccm_date).reduce((sum, p) => sum + (parseInt(p.cache.task_duration_ccm_sum_monthly) || 0), 0) / this.patients.filter(p => p.cache.ccm_date).length).toFixed(0) : 0;
    },

    percentOfMonth() {
      if (!this.authUser || !this.authUser.config) return 0;
      const start = moment().startOf("month").add(this.authUser.config.start_yellow_offset, "days");
      const end = moment().endOf("month").subtract(this.authUser.config.end_yellow_offset, "days");
      const total = moment(end).diff(start, "days");
      const daysFromStart = moment().diff(moment(start), "days");
      return Math.max(daysFromStart / total, 0);
      // return new Date().getDate() / (new Date((new Date().getFullYear()), (new Date().getMonth() +1), 0)).getDate();
    },
    goal() {
      if (!this.authUser || !this.authUser.config) return 0;
      return (this.percentOfMonth * this.authUser.config.task_dur_goal).toFixed(0);
    },
    activePrograms() {
      const rpmActive = this.patients.filter(p => p.cache.rpm_active).length;
      const ccmActive = this.patients.filter(p => p.cache.ccm_active).length;
      const apcmActive = this.patients.filter(p => p.cache.apcm_active).length;
      return { rpmActive, ccmActive, apcmActive };
    },
  },
}
</script>

<template>
  <div>
<!--    <h3 class="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>-->
    <dl class="mt-1 grid grid-cols-1 gap-2 sm:grid-cols-8">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">
          <el-tooltip :content="item.description" placement="top"><span>{{ item.name }}</span></el-tooltip>
        </dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          <div v-if="item.name==='Active Programs'">
            <p class="truncate text-sm font-bold text-black-500">RPM: <span class="text-blue-600">{{ item.stat.rpmActive }}</span></p>
            <p class="truncate text-sm font-bold text-black-500">CCM: <span class="text-blue-600">{{ item.stat.ccmActive }}</span></p>
            <p class="truncate text-sm font-bold text-black-500">APCM: <span class="text-blue-600">{{ item.stat.apcmActive }}</span></p>
          </div>
          <v-progress-circular
              v-else
              :rotate="90"
              :size="100"
              :width="10"
              :value="((item.stat / item.target) * 100).toFixed(0)"
              color="primary"
          >{{item.stat}}</v-progress-circular>
        </dd>
      </div>
    </dl>
  </div>
</template>

<style scoped>

</style>